import { findById, roles } from "../../routes/contentRoutes";

export interface IUserProps {
	id: number;
	email: string;
    login: string;
    nome: string;
    role: string;
}
export class User implements IUserProps {
    id: number;
    email: string;
    login: string;
    nome: string;
    role: string;

    constructor(user: IUserProps) {
        this.id = user.id;
        this.email = user.email;
        this.login = user.login;
        this.nome = user.nome;
        this.role = user.role;
    }

    isAdmin(): boolean {
      return this.role === roles.ADMINISTRADOR || this.role === roles.ADMINISTRADOR_PLANISA;
    }

    isAdminPlanisa(): boolean {
        return this.role === roles.ADMINISTRADOR_PLANISA;
    }

    isConsultorPlanisa(): boolean {
        return this.role === roles.CONSULTOR_PLANISA;
    }

    isAdminUnidade(): boolean {
        return this.role === roles.ADMINISTRADOR;
    }

    renderAtendimentos(): boolean {
        return findById('atendimentos')?.roles?.includes(this.role) || false;
    }

    renderStatus(): boolean {
        return findById('monitoramento')?.subMenu?.find(s => s.id == 'status')?.roles?.includes(this.role) || false;
    }

    renderDashboard(): boolean {
        return findById('dashboard')?.roles?.includes(this.role) || false;
    }

    renderCustoProcedimento(): boolean {
        return findById('custo-procedimento')?.roles?.includes(this.role) || false;
    }
}

export interface IUsuariosUnidade {
    usuario: {
        id: number;
        email: string;
        login: string;
        nome: string;
        ativo: boolean;
        estaCriptografado: boolean;
    },
    perfil?: {
        id: number,
        codigo: string,
        nome: string,
        descricao: string,
    }
}