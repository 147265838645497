import React, { lazy, useMemo } from 'react';
import Login from '../pages/auth/Login';
import LockScreen from '../pages/lockScreen/LockScreen';
import ReportDownload from '../pages/atendimentos/ReportDownload';

const CP = {
	HOME: lazy(() => import('../pages/home/HomePage')),
	ATENDIMENTOS: lazy(() => import('../pages/atendimentos/AtendimentosPage')),
	CUSTO_PROCEDIMENTO: lazy(() => import('../pages/custoProcedimento/CustoProcedimentoPage')),
	MAPEAR_CENTRO_CUSTO_INTEGRACAO: lazy(() => import('../pages/mapeamentos/centroDeCusto/MapeamentoCentroDeCustoPage')),
	MAPEAR_EXAMES_INTEGRACAO: lazy(() => import('../pages/mapeamentos/exames/MapeamentoExamesPage')),
	MAPEAR_PROCEDIMENTOS_INTEGRACAO: lazy(() => import('../pages/mapeamentos/procedimentos/MapeamentoProcedimentosPage')),
	MAPEAR_TIPO_ATENDIMENTO: lazy(() => import('../pages/mapeamentos/tipoAtendimento/MapeamentoTipoAtendimentoPage')),
	MAPEAR_TIPO_CONVENIO: lazy(() => import('../pages/mapeamentos/tipoConvenio/MapeamentoTipoConvenioPage')),
	MAPEAR_GRUPO_RECEITA: lazy(() => import('../pages/mapeamentos/grupoDeReceita/MapeamentoGrupoDeReceita')),
	COMPOSICAO_CUSTOS: lazy(() => import('../pages/configuracoesCalculo/composicaoCustos/ComposicaoCustosPage')),
	STATUS: lazy(() => import('../pages/monitoramento/status/StatusPage')),
	CONFIGURACOES: lazy(() => import('../pages/configuracoes/ConfiguracoesPage')),
	CONVERSOR: lazy(() => import('../pages/configuracoesCalculo/conversorMatMed/ConversorMatMedPage')),
	GLOSA: lazy(() => import('../pages/configuracoesCalculo/glosa/GlosaPage')),
	DASHBOARD: lazy(() => import('../pages/dashboard/DashboardPage')),
	CONFERENCIA: lazy(() => import('../pages/monitoramento/conferencia/ConferenciaPage')),
	METABASE: lazy(() => import('../pages/monitoramento/metabase/MetabasePage')),
};
const AUTH = {
	PAGE_404: lazy(() => import('../pages/auth/Page404')),
};

export interface RoutesProps {
	id: string;
	text: string;
	icon?: string;
	subMenu?: RoutesProps[];
	path?: string;
	element?: JSX.Element;
	roles?: string[];
	onlyRoute?: boolean;
}
export const enum roles {
	ADMINISTRADOR = 'ADMINISTRADOR',
	ELABORADOR_PROTOCOLOS = 'ELABORADOR_PROTOCOLOS',
	ELABORADOR_ANALISES = 'ELABORADOR_ANALISES',
	ANALISTA_STATUS = 'ANALISTA_STATUS',
	ANALISES_E_STATUS = 'ANALISES_E_STATUS',
	PROTOCOLOS_E_STATUS = 'PROTOCOLOS_E_STATUS',
	ADMINISTRADOR_PLANISA = 'ADMINISTRADOR_PLANISA',
	CONSULTOR_PLANISA = 'CONSULTOR_PLANISA',
	ANALISTA_FULL = 'ANALISTA_FULL',
	ANALISES_E_PROTOCOLOS = 'ANALISES_E_PROTOCOLOS'
}
const contentRoutes:RoutesProps[] = [
	/**
	 * Landing
	 */
	{
		id: 'home',
		text: 'Início',
		path: '/',
		icon: 'Home',
		roles: [],
		element: <CP.HOME />,
	},
	{
		id: 'atendimentos',
		text: 'Atendimentos',
		path: '/atendimentos',
		icon: 'PersonSearch',
		roles: [roles.ADMINISTRADOR_PLANISA, roles.CONSULTOR_PLANISA, roles.ADMINISTRADOR, roles.ELABORADOR_ANALISES, roles.ANALISES_E_STATUS, roles.ANALISTA_FULL, roles.ANALISES_E_PROTOCOLOS],
		element: <CP.ATENDIMENTOS />,
	},
	{
		id: 'custo-procedimento',
		text: 'Custo de Procedimentos',
		path: '/custo-procedimento',
		icon: 'Dashboard',
		roles: [roles.ADMINISTRADOR_PLANISA, roles.CONSULTOR_PLANISA, roles.ADMINISTRADOR, roles.ELABORADOR_PROTOCOLOS, roles.PROTOCOLOS_E_STATUS, roles.ANALISTA_FULL, roles.ANALISES_E_PROTOCOLOS],
		element: <CP.CUSTO_PROCEDIMENTO />,
	},
	{
		id: 'mapeamentos',
	 	text: 'Mapeamentos',
	 	path: '/mapeamentos',
	 	icon: 'AccountTree',
		roles: [roles.ADMINISTRADOR_PLANISA, roles.CONSULTOR_PLANISA, roles.ADMINISTRADOR],
	 	subMenu: [
			{
				id: 'centroDeCusto',
				text: 'Centros de Custos',
				path: '/mapear-centro-custo',
				icon: 'MonetizationOn',
				roles: [roles.ADMINISTRADOR_PLANISA, roles.CONSULTOR_PLANISA, roles.ADMINISTRADOR],
				element: <CP.MAPEAR_CENTRO_CUSTO_INTEGRACAO />,
			},
			{
				id: 'exames',
				text: 'Exames',
				path: '/mapear-exames',
				icon: 'Biotech',
				roles: [roles.ADMINISTRADOR_PLANISA, roles.CONSULTOR_PLANISA, roles.ADMINISTRADOR],
				element: <CP.MAPEAR_EXAMES_INTEGRACAO />,
			},
			{
				id: 'procedimentos',
				text: 'Procedimentos',
				path: '/mapear-procedimentos',
				icon: 'AirlineSeatFlat',
				roles: [roles.ADMINISTRADOR_PLANISA, roles.CONSULTOR_PLANISA, roles.ADMINISTRADOR],
				element: <CP.MAPEAR_PROCEDIMENTOS_INTEGRACAO />,
			},
			{
				id: 'tiposDeAtendimento',
				text: 'Tipos de Atendimento',
				path: '/mapear-tipo-atendimento',
				icon: 'Attribution',
				roles: [roles.ADMINISTRADOR_PLANISA, roles.CONSULTOR_PLANISA, roles.ADMINISTRADOR],
				element: <CP.MAPEAR_TIPO_ATENDIMENTO />,
			},
			{
				id: 'tiposDeConvenio',
				text: 'Tipos de Fonte Pagadora',
				path: '/mapear-tipo-fonte-pagadora',
				icon: 'AccountBox',
				roles: [roles.ADMINISTRADOR_PLANISA, roles.CONSULTOR_PLANISA, roles.ADMINISTRADOR],
				element: <CP.MAPEAR_TIPO_CONVENIO />,
			},
			{
				id: 'gruposDeReceita',
				text: 'Grupos de Receita',
				path: '/mapear-grupos-receita',
				icon: 'Task',
				roles: [roles.ADMINISTRADOR_PLANISA, roles.CONSULTOR_PLANISA, roles.ADMINISTRADOR],
				element: <CP.MAPEAR_GRUPO_RECEITA />,
			},
		]
	},
	{
		id: 'composicao',
		text: 'Configurações de Cálculo',
		path: '/composicao-custos',
		icon: 'SettingsInputComposite',
		roles: [roles.ADMINISTRADOR_PLANISA, roles.CONSULTOR_PLANISA, roles.ADMINISTRADOR],
		subMenu: [
			{
				id: 'composicao',
				text: 'Composição de Custos',
				path: '/composicao-custos',
				icon: 'AttachMoney',
				roles: [roles.ADMINISTRADOR_PLANISA, roles.CONSULTOR_PLANISA, roles.ADMINISTRADOR],
				element: <CP.COMPOSICAO_CUSTOS />,
			},
			{
				id: 'conversor',
				text: 'Conversor de Mat/Med',
				path: '/conversor-matmed',
				icon: 'Medication',
				roles: [roles.ADMINISTRADOR_PLANISA, roles.CONSULTOR_PLANISA, roles.ADMINISTRADOR],
				element: <CP.CONVERSOR />,
			},
			{
				id: 'glosa',
				text: 'Deduções de Receita',
				path: '/deducao-de-receita',
				icon: 'MoneyOff',
				roles: [roles.ADMINISTRADOR_PLANISA, roles.CONSULTOR_PLANISA, roles.ADMINISTRADOR],
				element: <CP.GLOSA />,
			},
		]
	},
	{
		id: 'monitoramento',
		text: 'Monitoramento',
		path: '/monitoramento',
		icon: 'Monitor',
		roles: [roles.ADMINISTRADOR_PLANISA, roles.CONSULTOR_PLANISA, roles.ADMINISTRADOR, roles.PROTOCOLOS_E_STATUS, roles.ANALISES_E_STATUS, roles.ANALISTA_STATUS, roles.ANALISTA_FULL],
		subMenu: [
			{
				id: 'status',
				text: 'Status',
				path: '/status',
				icon: 'Apps',
				roles: [roles.ADMINISTRADOR_PLANISA, roles.CONSULTOR_PLANISA, roles.ADMINISTRADOR, roles.PROTOCOLOS_E_STATUS, roles.ANALISES_E_STATUS, roles.ANALISTA_STATUS, roles.ANALISTA_FULL],
				element: <CP.STATUS />,
			},
			{
				id: 'conferencia',
				text: 'Conferência da receita',
				path: '/conferencia',
				icon: 'AttachMoney',
				roles: [roles.ADMINISTRADOR_PLANISA, roles.CONSULTOR_PLANISA, roles.ADMINISTRADOR, roles.ANALISTA_FULL],
				element: <CP.CONFERENCIA />,
			},
			{
				id: 'metabase',
				text: 'Roteiro de Análise',
				path: '/metabase',
				icon: 'Analytics',
				roles: [roles.ADMINISTRADOR_PLANISA, roles.CONSULTOR_PLANISA],
				element: <CP.METABASE />,
			},
		]
	},
	{
	 	id: 'configuracoes',
	 	text: 'Configurações',
	 	path: '/configuracoes',
	 	icon: 'Settings',
		roles: [roles.ADMINISTRADOR_PLANISA, roles.CONSULTOR_PLANISA, roles.ADMINISTRADOR, roles.ANALISTA_FULL],
		element: <CP.CONFIGURACOES />,
	},
	/**
	 * Routes only
	*/
	{
	  	id: 'dashboard',
	  	text: 'Dashboard',
	  	path: '/dashboard',
	  	icon: 'PieChartOutline',
	 	roles: [roles.ADMINISTRADOR_PLANISA, roles.CONSULTOR_PLANISA, roles.ADMINISTRADOR, roles.ANALISTA_FULL, roles.ANALISES_E_PROTOCOLOS],
	 	element: <CP.DASHBOARD />,
		onlyRoute: true,
	},
	{
		id: 'reportDownload',
		text: 'Report Download',
		path: '/reports/:id',
		element: <ReportDownload />,
		roles: [],
		onlyRoute: true,
	},
	{
		id: '404',
		text: 'Página não Encontrada',
		path: '/404',
		roles: [],
		element: <AUTH.PAGE_404 />,
		onlyRoute: true,
	},
	{
		id: 'login',
		text: 'Login',
		path: '/login',
		roles: [],
		element: <Login />,
		onlyRoute: true,
	},
	{
		id: 'lock',
		text: 'Lock',
		path: '/lock',
		roles: [],
		element: <LockScreen />,
		onlyRoute: true,
	},
	{
		id: 'atendimento',
		text: 'Atendimento',
		path: '/atendimento/:numeroAtendimento',
		element: <CP.ATENDIMENTOS />,
		roles: [roles.ADMINISTRADOR_PLANISA, roles.CONSULTOR_PLANISA, roles.ADMINISTRADOR, roles.ELABORADOR_ANALISES, roles.ANALISES_E_STATUS, roles.ANALISTA_FULL, roles.ANALISES_E_PROTOCOLOS],
		onlyRoute: true,
	},
	{
		id: 'acessoKpih',
		text: 'Acesso Kpih',
		path: '/acessoKpih',
		element: <CP.HOME />,
		roles: [],
		onlyRoute: true,
	},

];
export const filterMenuByRole = (role: string): RoutesProps[] => {
	return contentRoutes
			.filter(item => !item.onlyRoute && (item.roles?.length === 0 || item.roles?.includes(role)))
			.map(item => {
				if (item.subMenu && item.subMenu.length > 0) {
					item.subMenu = item.subMenu.filter(item => !item.onlyRoute && (item.roles?.length === 0 || item.roles?.includes(role)));
				}
				return item;
			});
};
export const filterRoutesByRole = (role: string): RoutesProps[] => {
	let routes:RoutesProps[] = [];
	contentRoutes
			.filter(item => item.roles?.length === 0 || item.roles?.includes(role))
			.forEach(item => {
				if (item.subMenu && item.subMenu.length > 0) {
					item.subMenu.filter(item => item.roles?.length === 0 || item.roles?.includes(role))
									.forEach(sub => routes.push(sub))
				} else {
					routes.push(item);
				}
			});
	return routes;
};
export const findById = (id: string): RoutesProps | undefined => {
	return contentRoutes
			.find(item => item.id === id);
};

export default contentRoutes;